import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import WebriQForm from '@webriq/gatsby-webriq-form'
import SEO from '../components/seo'

class ContactPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div className="col-6">
          <h1 className="mt-20">Send us your thoughts...</h1>
          <WebriQForm
            method="POST"
            data-form-id="9af15e69-1223-4445-85b4-38dca65823f2"
            data-thankyou-url="/thank-you"
            name="Newsletter Form"
            scriptSrc="https://pk3v3a4ale.execute-api.us-west-2.amazonaws.com/dev/js/initReactForms"
          >
            <div className="form-row">
              <div className="col-12 mb-3">
                <div className="form-group">
                  <input
                    className="form-control"
                    type="name"
                    name="First Name"
                    id="first_name"
                    placeholder="Full Name"
                  />
                  <input
                    type="text"
                    className="form-control"
                    type="name"
                    name="Last Name"
                    id="last_name"
                    placeholder="Last Name"
                  />
                  <input
                    className="form-control"
                    type="email"
                    name="Email Address"
                    id="email_address"
                    placeholder="info@yourmail.com"
                  />
                  <div className="form-group">
                    <input
                      type="text"
                      name="Subject"
                      className="form-control"
                      id="Subject"
                      placeholder="Subject"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="Message"
                      id="Message"
                      rows="7"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input type="file" name="Attachment" multiple />
                    </div>
                  </div>
                  <small className="text-dark form-text mt-2">
                    We will never share your email with anyone else.
                  </small>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <div className="webriq-recaptcha" />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group">
                  <button className="btn btn-primary hvr-shadow" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </WebriQForm>
        </div>
      </Layout>
    )
  }
}
export default ContactPage

export const ContactPageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
